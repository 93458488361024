import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useParts() {
  const part = ref({})
  const parts = ref([])
  const searchPart = ref('')
  const loading = ref(false)

  const getParts = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/parts', { params })
        .then(response => {
          const { data } = response.data
          parts.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  const getPart = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/parts/${id}`)
        .then(response => {
          const { data } = response
          part.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  return {
    part,
    parts,
    searchPart,

    getPart,
    getParts,
  }
}
