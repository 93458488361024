var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Inventory")]),_c('v-card-text',[_c('v-fade-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionAlert),expression:"actionAlert"}],attrs:{"border":"left","color":"success","dark":"","text":"","dismissible":""}},[_vm._v(" "+_vm._s(_vm.actionAlertMessage)+" ")])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchSubmit.apply(null, arguments)},"click:clear":_vm.clearSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.parts,"options":_vm.options,"server-items-length":_vm.totalParts,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }