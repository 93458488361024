<template>
  <v-card>
    <v-card-title>Inventory</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="searchSubmit"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <!-- <v-btn
          color="secondary"
          outlined
          disabled
          class="mb-4"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="parts"
        :options.sync="options"
        :server-items-length="totalParts"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import useUIResolver from '../useUIResolver'
import useParts from '../job-orders/useParts'

const defaultAlertMessage = 'Part successfully saved.'

export default {
  setup() {
    const partData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const partsEditorDialog = ref(false)
    const searchQuery = ref('')
    const totalParts = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['name'],
      sortDesc: [false],
    })
    const dialogDelete = ref(false)

    onMounted(() => {
      actionAlertMessage.value = defaultAlertMessage
    })

    const {
      part, parts, getParts, loading, searchPart,
    } = useParts()
    const { partTableHeaders } = useUIResolver()

    const fetchParts = () => {
      getParts({ ...options.value, ...{ search: searchQuery.value } }).then(response => {
        const { total } = response.data
        totalParts.value = total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    const searchSubmit = () => {
      options.value.page = 1
      fetchParts()
    }

    const clearSearch = () => {
      searchQuery.value = null
      fetchParts()
    }

    const editItem = item => {
      // show parts-editor dialog
      partsEditorDialog.value = true

      // assign the select part
      partData.value = item
    }

    const deleteItem = () => {
      axios.delete(`/parts/${partData.value.id}`).then(response => {
        actionAlertMessage.value = 'Part successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchParts()
      })
    }

    const deleteItemConfirm = item => {
      partData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchParts)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      partData,
      headers: partTableHeaders(),
      searchQuery,

      totalParts,
      footerProps,
      options,

      // useParts
      part,
      loading,
      searchPart,
      parts,
      getParts,

      // functions
      clearSearch,
      searchSubmit,
      partsEditorDialog,
      fetchParts,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
